// https://nextjs.org/docs/app/building-your-application/configuring/environment-variables

import { AppConfig } from '@/types/configs'

type Configs = {
  appConfig?: AppConfig
}

const configs: Configs = {}

export default configs
