export const SEARCH_PARAM_KEYS = {
  HTC_TOKEN: 'htc_account_access_token',
  RETICULUM_TOKEN: 'reticulum_token',
  EMAIL: 'email',
  RETRY_AFTER: 'retry_after',
  AVATAR: 'avatar_url',
  SNAPSHOT: 'snapshot_url',
  HEAD_ICON: 'head_icon_url',
  DISPLAY_NAME: 'display_name',
  IS_HALF_BODY: 'is_half_body',
  GENDER: 'gender',
  IS_NEW_USER: 'is_new_user',
  OPEN_CREATE_DIALOG: 'create',
} as const
