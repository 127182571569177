import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function applyHyphen(string: string) {
  return string?.replace('_', '-')
}

export function applyUnderline(string: string) {
  return string?.replace('-', '_')
}

export function pickRequiredParams(data: { [paramsKey: string]: any }[], paramsKey: string) {
  return data.map((dataItem, i) => {
    return dataItem[`${paramsKey}`] || null
  })
}
