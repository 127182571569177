'use client'

import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { syncAppConfig } from '@/configs/utils'
import { AppConfig } from '@/types/configs'
import { HUBS_STORE_LOCAL_KEY, HubsStoreState } from '@/types/store'
import { extractUsernameFromEmail } from '@/lib/auth'

type ConfigsContextType = {
  store: HubsStoreState | null
  setStore: Dispatch<SetStateAction<HubsStoreState>>
  removeStore: () => void
  accountType: 'wallet_account' | 'htc_account' | 'guest'
  accountId: string
}

const ConfigsContext = createContext<ConfigsContextType | null>(null)

type ConfigsProviderProps = PropsWithChildren<{
  appConfig: AppConfig
}>

export const ConfigsProvider = ({ appConfig, children }: ConfigsProviderProps) => {
  const [isLoadedAppConfig, setIsLoadedAppConfig] = useState(false)
  const [store, setStore, removeStore] = useLocalStorage<HubsStoreState>(HUBS_STORE_LOCAL_KEY, {})

  const credentials = store?.credentials
  const viveportAuth = store?.viveportAuth
  const email = credentials?.email
  const walletAddress = viveportAuth?.walletAddress
  const accountEmail = viveportAuth?.accountEmail
  const accountPhoneNumber = viveportAuth?.accountPhoneNumber

  const accountId = useMemo(() => extractUsernameFromEmail(email!), [email])

  const accountType = useMemo(() => {
    if (walletAddress) {
      return 'wallet_account'
    }
    if (accountEmail || accountPhoneNumber) {
      return 'htc_account'
    }
    return 'guest'
  }, [walletAddress, accountEmail, accountPhoneNumber])

  useEffect(() => {
    if (appConfig) {
      //* we use this way to make sure configs.appConfig in client side
      syncAppConfig(appConfig)
      setIsLoadedAppConfig(true)
    }
  }, [appConfig])

  return (
    //* we use this way to ensure that the children are rendered only after the appConfig is loaded
    <ConfigsContext.Provider
      value={{
        store,
        setStore,
        removeStore,
        accountType,
        accountId,
      }}
    >
      {isLoadedAppConfig && children}
    </ConfigsContext.Provider>
  )
}

export const useConfigsContext = () => {
  const value = useContext(ConfigsContext)

  if (value == null) {
    throw new Error('useConfigsContext cannot be used outside of ConfigsProvider')
  }

  return value
}
