import { getCmsBaseURL } from '@/configs/utils'
import { DeviceType, VendorType } from '@/types/configs'
import {
  API_GET_ACTIVITY_SHOWCASE,
  API_GET_EDITOR_CHOICES,
  API_GET_ROOMS_V1,
  API_GET_EVENTS,
  API_GET_MY_ROOMS_V2,
  API_GET_Recommend_CREATORS,
  API_GET_ROOMS_V2,
  API_GET_MY_GIVEAWAY_LIST,
  API_GET_SCENES,
  API_ADD_ROOM_UPLOAD_ASSET,
  API_DELETE_MY_ROOMS_V2,
  API_UPDATE_MY_ROOMS,
  API_GET_NFT,
  API_GET_ROOM_TAGS,
  API_GET_THEMES,
  API_GET_FOLLOWING_ROOMS,
  API_GET_GUEST_INFO,
  API_GET_ME_V4,
  API_DELETE_CO_OWNED_ROOM,
  API_GET_LIKED_ROOMS_V3,
} from '@/services/api'
import { ImageAsset, PolicyValue, VideoAsset } from '@/types/world'
import { env } from 'next-runtime-env'

// Basic types
export type PaginationPages = {
  total: number
  start: number
  limit: number
  prev: string
  next: string
}

export type Host = {
  id?: string
  display_name?: string
}

export type Speaker = {
  id?: string
  display_name?: string
  description?: string
  image?: string
}

export type Owner = {
  id: string
  display_name: string
  snapshot_url: string
  htc_account_id: string
  head_icon_url: string
}

export type Room = {
  id: string
  title: string
  description?: string
  image: string
  description_plaintext: string
  hubs_url: string
  hub_sid: string
  scene_sid: string
  policy: PolicyValue
  view_count: number
  like_count: number
  is_distributed_room: boolean
  is_opened: boolean
  is_voucher_room: boolean
  owner: Owner
  total_vram_kb: number
  is_liked?: boolean
  is_live?: boolean
  online_counts?: number
}

export type ThemeRoom = {
  id: string
  title: string
  image: string
  description: string
  description_plaintext?: string
  hub_sid: string
  hubs_url: string
  scene_sid?: string
  theme_order: number
  view_count: number
  like_count: number
  policy: PolicyValue
  is_distributed_room: boolean
  is_opened: boolean
  is_live?: boolean
  online_counts?: number
  is_voucher_room: boolean
  owner: Owner
}

export type SubImage = {
  directus_files_id?: string
}

export type Video = {
  video_id?: string
}

export type HashTag = {
  id?: string
  value: string
}

export type Category = {
  id?: string
  value: string
}

export type EditorChoice = {
  id: string
  image: string
  banner_image: string
  title: string
  description: string
  is_pinned: boolean
  rooms: Room[]
}

// function types
export type Event = {
  id: string
  image: string
  title: string
  description: string
  logo?: string
  agenda?: string
  start_time?: string
  end_time?: string
  is_liked?: boolean
  is_promoted?: boolean
  like_count?: number
  view_count?: number
  hosts: Host[]
  speakers: Speaker[]
  rooms: Room[]
  sub_images: SubImage[]
  videos: Video[]
  hashtags: HashTag[]
  category: Category[]
}

export type ActivityShowcaseList = {
  background?: string
  video?: VideoAsset
  description: string
  description_html?: string
  title: string
  action_title: string
  action_link: string
  card?: string
  view_count?: number
  like_count?: number
  online_counts?: number
  preferred_devices?: DeviceType[]
}

export enum ActivityShowcaseCategory {
  Landing = 'landing',
  Explore = 'explore',
}

export const fetchCMSApiWithStatusCode = <Response = Record<string, unknown>>(
  url: string,
  method: RequestInit['method'] = 'GET',
  payload: RequestInit['body'],
  params: Omit<RequestInit, 'method' | 'body'> = {},
  htcToken = '',
  isSSR: boolean = false,
): Promise<{ status: number; results: Response; headers: Headers } | void> => {
  const cmsBaseURL = getCmsBaseURL()
  const cmsUrl = `${cmsBaseURL}${url}`

  const init: RequestInit = {
    headers: {
      'content-type': 'application/json',
    },
    method,
    ...params,
  }
  if (htcToken) {
    init.headers = {
      ...init.headers,
      ...{ authkey: htcToken },
    }
  }
  if (isSSR) {
    const userAgent = env('USER_AGENT')
    if (userAgent) {
      init.headers = {
        ...init.headers,
        ...{ 'User-Agent': userAgent },
      }
    }
  }
  if (payload) {
    init.body = JSON.stringify(payload)
  }
  return fetch(cmsUrl, init)
    .then(async (response) => {
      const result = await response.text()
      try {
        return { status: response.status, results: JSON.parse(result), headers: response.headers }
      } catch (e) {
        // Don't return json.
        return { status: response.status, results: result, headers: response.headers }
      }
    })
    .catch((error) => {
      console.error('fetchCMSApiWithStatusCode error:', error)
    })
}

export function getEditorChoices(
  start: number = 0,
  limit: number = 0,
  category: 'landing' | 'explore' = 'landing',
  totalVramKb: number,
  isSSR: boolean = true,
) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<EditorChoice>>(
    `${API_GET_EDITOR_CHOICES}?start=${start}&limit=${limit}&category=${category}&total_vram_kb=${totalVramKb}`,
    'GET',
    null,
    {},
    '',
    isSSR,
  )
}

export function getActivityShowcase(
  category: ActivityShowcaseCategory = ActivityShowcaseCategory.Landing,
  start: number = 0,
  limit: number = 0,
) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<ActivityShowcaseList>>(
    `${API_GET_ACTIVITY_SHOWCASE}?start=${start}&limit=${limit}&category=${category}`,
    'GET',
    null,
    {},
    '',
    true,
  )
}

export function getEvents(totalVramKb: number) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Event>>(
    `${API_GET_EVENTS}?total_vram_kb=${totalVramKb}`,
    'GET',
    null,
    {},
    '',
    true,
  )
}

export function getTheme(themeId: string) {
  return fetchCMSApiWithStatusCode<Theme>(`${API_GET_THEMES}/${themeId}`, 'GET', null, {}, '', true)
}

export function postLikeRoom(roomId: string, htcToken: string) {
  return fetchCMSApiWithStatusCode<Room>(
    `${API_GET_ROOMS_V1}/${roomId}/liked`,
    'POST',
    null,
    {},
    htcToken,
  )
}

export function postUnlikeRoom(roomId: string, htcToken: string) {
  return fetchCMSApiWithStatusCode<Room>(
    `${API_GET_ROOMS_V1}/${roomId}/unliked`,
    'POST',
    null,
    {},
    htcToken,
  )
}

export function getRoom(roomId: string, htcToken: string) {
  return fetchCMSApiWithStatusCode<Room>(`${API_GET_ROOMS_V1}/${roomId}`, 'GET', null, {}, htcToken)
}

export type RoomsResults = {
  results: Room[]
  pages: PaginationPages
}

export function getMyRooms(htcToken: string, start: number = 0, limit: number = 0) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Room>>(
    `${API_GET_MY_ROOMS_V2}?limit=${limit}&start=${start}`,
    'GET',
    null,
    {},
    htcToken,
  )
}

export function getCoOwnedRooms(htcToken: string) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Room>>(
    `${API_GET_ME_V4}/co-owned-worlds`,
    'GET',
    null,
    {},
    htcToken,
  )
}

type RoomsQueryParams = {
  htc_account_id: string
  tag: string
  total_vram_kb: number
  start: number
  limit: number
}

function getAppendedQueryUrl(url: string, params: any) {
  const cmsBaseURL = getCmsBaseURL()
  const fullUrl = `${cmsBaseURL}${url}`
  const _url = new URL(fullUrl)

  Object.entries(params).forEach(([key, value]) => {
    const valueString = value + ''
    if (valueString) {
      _url.searchParams.append(key, valueString)
    }
  })

  return `${_url.pathname}${_url.search}`
}

export function getPublicRooms(
  params: Partial<RoomsQueryParams>,
  htcToken?: string,
  isSSR = false,
) {
  const url = getAppendedQueryUrl(API_GET_ROOMS_V2, params)
  return fetchCMSApiWithStatusCode<PaginatedResponse<Room>>(url, 'GET', null, {}, htcToken, isSSR)
}

export function getLikedRooms(htcToken: string, params: Partial<RoomsQueryParams>) {
  const url = getAppendedQueryUrl(API_GET_LIKED_ROOMS_V3, params)
  return fetchCMSApiWithStatusCode<PaginatedResponse<Room>>(url, 'GET', null, {}, htcToken)
}

export type UserProfile = {
  id: string
  wallet_address: string
  htc_account_email: string
  htc_account_phone_number: string
  display_name: string
  htc_account_id: string
  like_rooms: Room[]
  liked_events: Event[]
  oursong_account_id: string
  is_over_18: boolean
  active_avatar: {
    snapshot_url: string
    head_icon_url: string
    gender: number
    is_half_body: string
    data_type: string
  }
}

export function getUserProfile(htcAccountId: string) {
  return fetchCMSApiWithStatusCode<Partial<UserProfile>>(
    `${API_GET_GUEST_INFO}?htc_account_id=${htcAccountId}`,
    'GET',
    null,
    {},
    '',
    true,
  )
}

export type recommendCreatorResults = {
  results: Creator[]
  pages: PaginationPages
}

export function getRecommendCreators(htcToken: string) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Creator>>(
    API_GET_Recommend_CREATORS,
    'GET',
    null,
    {},
    htcToken,
  )
}

export type Creator = {
  id: string
  community_user_id: string
  display_name: string
  head_icon_url: string
  htc_account_id: string
}

export type Selection = {
  title: string
  scene_sid: string
  images: ImageAsset[]
  videos: VideoAsset[]
}

export type SelectedItem = {
  item: string
  redeem_time: string
}

export type Giveaway = {
  id: string
  title: string
  room_size: number
  chance: number
  type: 'scene'
  selections: Selection[]
  selected: SelectedItem[]
}

export function getMyGiveaways(htcToken: string) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Giveaway>>(
    API_GET_MY_GIVEAWAY_LIST,
    'GET',
    null,
    {},
    htcToken,
  )
}

export function getAScene(htcToken: string, sceneSid: string) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Giveaway>>(
    `${API_GET_SCENES}/${sceneSid}`,
    'GET',
    null,
    {},
    htcToken,
  )
}

type CreateAGiveawayRoomResults = {
  results: CreateAGiveawayRoomData
}

export type CreateAGiveawayRoomData = {
  scene_sid: string
  policy: Room['policy']
  title: string
  image: ImageAsset['id']
  features: {
    spatial_sound: boolean
  }
  passcode?: string
  description_plaintext: string
}

export function postCreateAGiveawayRoom(
  htcToken: string,
  giveawayId: Giveaway['id'],
  data: CreateAGiveawayRoomData,
) {
  return fetchCMSApiWithStatusCode<CreateAGiveawayRoomResults>(
    `${API_GET_MY_GIVEAWAY_LIST}/${giveawayId}/create-room`,
    'POST',
    // @ts-ignore
    data,
    {},
    htcToken,
  )
}

export const fetchUploadRoomImage = async (htcToken: string, file: File) => {
  const formData = new FormData()
  formData.append('asset', file)
  const params = {
    headers: {},
    body: formData,
  }
  return fetchCMSApiWithStatusCode(API_ADD_ROOM_UPLOAD_ASSET, 'POST', null, params, htcToken)
}

export function deleteRoom(htcToken: string, roomId: string) {
  return fetchCMSApiWithStatusCode<RoomsResults>(
    `${API_DELETE_MY_ROOMS_V2}/${roomId}`,
    'DELETE',
    null,
    {},
    htcToken,
  )
}

export function withdrawCoOwnedRoom(htcToken: string, roomId: string, profileId: string) {
  return fetchCMSApiWithStatusCode<RoomsResults>(
    `${API_DELETE_CO_OWNED_ROOM}/${roomId}/co-owners/${profileId}`,
    'DELETE',
    null,
    {},
    htcToken,
  )
}

export function getEntryPass(
  profileId: string,
  chain: string = 'eth',
  vendorType: VendorType = 'vivebytes',
  htcToken: string,
) {
  return fetchCMSApiWithStatusCode<Room>(
    `${API_GET_NFT}/${profileId}/nft/${chain}/${vendorType}/vouchers`,
    'GET',
    null,
    {},
    htcToken,
  )
}

export type PatchRoomData = {
  policy: Room['policy']
  title: string
  image: ImageAsset['id']
  passcode?: string
  features: {
    spatial_sound: boolean
  }
  description_plaintext: string
}

export function patchRoom(htcToken: string, roomId: string, data: PatchRoomData) {
  return fetchCMSApiWithStatusCode<Room>(
    `${API_UPDATE_MY_ROOMS}/${roomId}`,
    'PATCH',
    // @ts-ignore
    data,
    {},
    htcToken,
  )
}

export type Tag = {
  name: string
}
type PaginatedResponse<T> = { results: T[]; pages: PaginationPages }

export function getRoomTags() {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Tag>>(
    API_GET_ROOM_TAGS,
    'GET',
    null,
    {},
    '',
    true,
  )
}

export type Theme = {
  id: string
  title: string
  description: string
  image: string
  banner_image: string
  policy: string
  hosted: string
  logo: string
  rooms: ThemeRoom[]
}

export function getThemes() {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Theme>>(
    API_GET_THEMES,
    'GET',
    null,
    {},
    '',
    true,
  )
}

export function getFollowingRooms(
  htcToken: string,
  start: number = 0,
  limit: number = 0,
  totalVramKb: number,
) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Room>>(
    `${API_GET_FOLLOWING_ROOMS}?limit=${limit}&start=${start}&total_vram_kb=${totalVramKb}`,
    'GET',
    null,
    {},
    htcToken,
  )
}

export function getGuestAccount(htcToken: string, htcAccountId: string) {
  return fetchCMSApiWithStatusCode<PaginatedResponse<Room>>(
    `${API_GET_GUEST_INFO}?htc_account_id=${htcAccountId}`,
    'GET',
    null,
    {},
    htcToken,
  )
}
