import { SEARCH_PARAM_KEYS } from '@/constants/auth'
import { GetProfileMeResponse } from '@/services/auth'
import { HubsStoreState } from '@/types/store'

const { HTC_TOKEN, RETICULUM_TOKEN, EMAIL, RETRY_AFTER } = SEARCH_PARAM_KEYS

export const createCredentials = (qs: URLSearchParams) => {
  const retryAfter = parseInt(qs.get(RETRY_AFTER)!, 0)
  const expiredAt = Date.now() + retryAfter * 1000
  return {
    token: qs.get(RETICULUM_TOKEN) || '',
    email: qs.get(EMAIL) || '',
    htcToken: qs.get(HTC_TOKEN) || '',
    expiredAt: expiredAt,
  }
}

export const createProfile = (result: GetProfileMeResponse) => ({
  id: result.id,
  displayName: result.displayName,
  isOver18: result.isOver18,
  userId: result.userId,
  avatarId: result.activeAvatar.id,
  avatarSnapshot: result.activeAvatar.snapshot,
  avatarGlb: result.activeAvatar.glbUrl,
  isHalfBody: result.activeAvatar.isHalfBody,
  avatarGenderKey: result.activeAvatar.gender,
  avatarDataType: result.activeAvatar.dataType,
})

export const createShareComponentProfile = (
  profile: HubsStoreState['profile'],
  points?: number,
) => ({
  displayName: profile?.displayName,
  activeAvatar: {
    id: profile?.avatarId,
    snapshot_url: profile?.avatarSnapshot,
    avatar_url: profile?.avatarGlb,
    head_icon_url: profile?.avatarSnapshot,
    gender: profile?.avatarGenderKey,
    data_type: profile?.avatarDataType,
    is_half_body: false,
  },
  userID: profile?.userId,
  points,
})

export const createViveportAuth = (result: GetProfileMeResponse) => ({
  walletAddress: result.walletAddress,
  accountEmail: result.accountEmail,
  accountPhoneNumber: result.accountPhoneNumber,
})

export const removeKeysFromSearchParams = (keys: string[]) => {
  const searchParams = new URLSearchParams(location.search)
  keys.forEach((key) => searchParams.delete(key))
  const searchParamString = searchParams.toString()
  const newURL = `${location.origin}${location.pathname}${searchParamString && '?' + searchParamString}`
  window.history.replaceState(null, '', newURL)
}

export const extractUsernameFromEmail = (email: string) => {
  if (!email) return ''
  const atIndex = email.indexOf('@')
  return email.slice(0, atIndex)
}
